import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import postAxios from "services/axios-post";

import DatetimePicker from "vuetify-datetime-picker";
import AddTiresDialog from "@/components/AddTiresDialog";
import EditUsedCarDialog from "@/components/EditUsedCarDialog";
import moment from "moment";
import putAxios from "../../../services/axios-put";

Vue.use(VueAxios, axios, moment);

Vue.use(DatetimePicker);

export default {
  components: {
    AddTiresDialog,
    EditUsedCarDialog,
  },
  data() {
    return {
      componentKey: 0,
      snackMessage: "",
      snackbar: false,
      color: "primary",
      bidStartDateModal: false,
      bidEndDateModal: false,
      pageCount: 0,
      imgFile: null,
      editImgFile: null,
      defaultImgPath: null,
      defaultImgPathEdit: null,
      imgUrl: null,
      pageSizeList: [10, 20, 50, 100],
      pagination: {
        limit: 10,
        noOfPage: 1,
        page: 1,
      },
      bidTypeSelection: [
        { value: "AUTOBID", name: "AUTOBID" },
        { value: "CLOSEBID", name: "CLOSEBID" },
      ],
      editDealerJSON: null,
      editCarModelJSON: null,
      statusRejectedReason: [],
      delUsedCar: {
        id: "",
        name: "",
      },
      nameSearch: "",
      total: 0,
      deleteDialog: false,
      token: "",
      headers: [
        { text: "Product Name", value: "name" },
        { text: "Price", value: "price" },
        { text: "Code", value: "code" },
        { text: "Images", value: "displayImgs" },
        { text: "Rim Size", value: "rimSize" },
        { text: "Section With", value: "sectionWith" },
        { text: "Edit" },
        { text: "Delete" },
        { text: "Id", value: "id" },
      ],
      biddingCar: {
        bidStartPrice: 0,
        biddingScheduleId: "",
        carId: "",
        contactNum: "",
        editorNotes: "",
        expectPrice: 0,
        handoverDate: "",
        inspected: "",
        goldPrice: 0,
        ownerName: "",
        paperValue: 0,
        bidType: "AUTOBID",
      },
      scheduleBidding: {
        bidStartTime: "",
        bidEndTime: "",
      },
      usedCar: [],
      descriptionLimit: 60,
      coeTypeList: [],
      idTypeList: [],
      vesCategoryList: [],
      coeCatList: [],
      displayImgsFiles: [],
      displayVideoFiles: [],
      displayImgsFilesEdit: [],
      rejectedReasons: [
        { value: "Inappropriate Watermark" },
        { value: "Invalid information (Car plate, ID type and ID)" },
        { value: "Broken image" },
      ],
      editUsedCarImgs: [],
      carPlateSearch: "",
    };
  },
  mounted() {
    this.fetchTire();
  },
  methods: {
    paginationChangeHandler() {
      this.fetchTire();
    },
    delButtonClick(item) {
      this.delUsedCar.id = item.id;
      this.delUsedCar.name = item.name;
      this.deleteDialog = true;
    },
    doDelUsedCar() {
      const self = this;
      // const param = {
      //   id: self.delUsedCar.id,
      // };
      putAxios(
        `${process.env.VUE_APP_SERVER_URL}/tireSystem/delTire?id=${self.delUsedCar.id}`,
        null,
        self.token
      ).then(res => {
        if (res.data) {
          self.deleteDialog = false;
          self.fetchTire();
          self.showSnack("Delete Tire Successfully", "success");
        }
      });
    },
    searchType() {
      this.fetchTire();
    },
    handlePageChange(newPage) {
      this.pagination.page = newPage;
      this.fetchTire();
    },
    handlePageSizeChange(newPageSize) {
      this.pagination.limit = newPageSize;
      const newPage = this.total / newPageSize;
      if (this.pagination.page > newPage) {
        this.pagination.page = Math.ceil(newPage);
      }
      this.fetchTire();
    },
    fetchTire() {
      const self = this;
      const params = {
        // page: self.pagination.page - 1,
        // size: self.pagination.limit,
        // name: self.nameSearch,
        // carPlate: self.carPlateSearch,
        // sort: "car.createTime,desc",
        searchContent: self.nameSearch,
      };
      postAxios(
        `${process.env.VUE_APP_SERVER_URL}/tireSystem/search`,
        params,
        self.token
      ).then(res => {
        if (res.data) {
          self.usedCar = res.data.data.content;
          self.pagination.noOfPage = res.data.data.totalPages;
        }
      });
    },
    showSnack(msg, color) {
      this.snackMessage = msg;
      this.color = color;
      this.snackbar = true;
    },
    add() {
      this.fetchTire();
      this.showSnack("Add Car Successfully", "success");
    },
    edit() {
      this.fetchTire();
      this.showSnack("Edit Car Successfully", "success");
    },
    clickAuction(auctionFilePath) {
      window.open(auctionFilePath);
    },
  },
};
