import getAxios from "services/axios-get";
import postAxios from "services/axios-post";
import createWatermark from "../../../services/create-watermark";
import uploadSignedImage from "../../../services/aws-upload-image";
import ImgGroupInput from "@/components/ImgGroupInput";
import SalespersonGroupInput from "@/components/SalespersonGroupInput";

export default {
  components: {
    ImgGroupInput,
    SalespersonGroupInput,
  },
  props: {
    vesCategoryList: Array,
    idTypeList: Array,
  },
  data() {
    return {
      addTire: {
        name: "",
        aspectRatio: "",
        code: "",
        defaultImgPath: "",
        displayImgs: [],
        loadIndex: 0,
        price: 0,
        rimSize: 0,
        sectionWith: 0,
        speedRating: "",
      },
      radioWatermark: "watermark",
      loadingUpd: false,
    };
  },
  methods: {
    async doAddTire() {
      const self = this;
      self.loadingUpd = true;
      try {
        const isObscure = this.radioWatermark == "watermark" ? true : false;
        const imgResLists = self.$refs.imgs.imgs;
        let img;
        for (let i = 0; i < imgResLists.length; i++) {
          img = imgResLists[i];
          if (img) {
            const uploadUrl = await getAxios(
              `${process.env.VUE_APP_SERVER_URL}/api/v2/file-storage/pre-signed-urls`,
              {
                format: "png",
                waterMark: isObscure,
              },
              this.token
            );
            if (isObscure) {
              const watermarkedImage = await createWatermark(img.file);
              if (watermarkedImage) {
                await uploadSignedImage(
                  watermarkedImage,
                  uploadUrl.data.waterMarkedUrl
                );
                self.addTire.displayImgs.push(uploadUrl.data.waterMarkedName);
              }
            } else {
              await uploadSignedImage(img.file, uploadUrl.data.originalUrl);
              self.addTire.displayImgs.push(uploadUrl.data.originalName);
            }
          }
        }
        self.addTire.defaultImgPath = self.addTire.displayImgs.shift();
        await postAxios(
          `${process.env.VUE_APP_SERVER_URL}/tireSystem/addTire`,
          self.addTire,
          self.token
        )
          .then(res => {
            if (res.data) {
              self.show = false;
              self.$emit("add");
              this.resetAddTire();
              self.loadingUpd = false;
            }
          })
          .finally(() => (this.loadingUpd = false));
      } catch (e) {
        self.$emit("error", e);
        self.loadingUpd = false;
        console.error(e);
      }
    },
    async resetAddTire() {
      this.addTire = {
        name: "",
        aspectRatio: "",
        code: "",
        defaultImgPath: "",
        displayImgs: [],
        loadIndex: 0,
        price: 0,
        rimSize: 0,
        sectionWith: 0,
        speedRating: "",
      };
    },
  },
};
